import React from 'react';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import { successMsg } from '../../../cache/vars';
import {
  REVERT_CART_TO_ORIGINAL,
  UPDATE_OR_CREATE_CART_DETAILS,
  UPDATE_OR_CREATE_FULLY_EDITABLE_CART_DETAILS,
  UPDATE_RESTAURANT_GOOGLE_URL,
} from '../../../queries/vip-workflow';
import { CARD_FORM_SCHEMA } from '../../../utils/cardConfigs';
import FormSection from './FormSection';
import { GET_PROFILE } from '../../../queries/auth';

const EditCardForm = ({
  modalGroup,
  handleAirportTransferInputs,
  handleVegasLimoTransferInputs,
  handleInputs,
  modalState,
  setAirportTransferType,
  airportTransferType,
  vegasLimoTransferType,
  setVegasLimoTransferType,
  setModalState,
  setPackageDetailsId,
  setModalGroup,
  setModalName,
  setIsModalOpened,
  setPackageOrderWithDetails,
  invoiceId,
  activeCardsFilter,
}: any) => {
  const client = useApolloClient();

  const [getUserProfile, getUserProfileResponse] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',
  });

  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

  const user = getUserProfileResponse?.data?.getProfile?.username
    ? getUserProfileResponse?.data?.getProfile?.username
    : getUserProfileResponse?.data?.getProfile?.email;

  const hanldeRevertCartToOriginal = async (
    packageOrderId: number,
    cartId: number,
    fieldToUpdate: string,
  ) => {
    const { data } = await client.mutate({
      mutation: REVERT_CART_TO_ORIGINAL,
      variables: { packageOrderId, cartId, fieldToUpdate },
    });

    const cartInfo = {
      cartId: data?.revertCartDataToOriginal.cartId,
      packageDetailsId: data?.revertCartDataToOriginal.packageDetailsId,
      cartData: JSON.parse(data?.revertCartDataToOriginal.cartJsonData),
      packageOrderId: data?.revertCartDataToOriginal.packageOrderId,
      cardTitle: data?.revertCartDataToOriginal.cardTitle,
    };

    setModalState(cartInfo);

    if (setPackageOrderWithDetails) {
      setPackageOrderWithDetails((prev: any) => {
        return {
          ...prev,
          orderDetails: prev.orderDetails.map((item: any) =>
            item.packageDetailsId === cartInfo.packageDetailsId
              ? {
                  ...item,
                  cartDetails: {
                    cartId: data?.revertCartDataToOriginal.cartId,
                    packageDetailsId: data?.revertCartDataToOriginal.packageDetailsId,
                    cartJsonData: data?.revertCartDataToOriginal.cartJsonData,
                    packageOrderId: data?.revertCartDataToOriginal.packageOrderId,
                  },
                }
              : item,
          ),
        };
      });
    }

    successMsg('Value Reverted To Original');
  };
  
  const [updateRestaurantGoogleUrl, { data }] = useMutation(UPDATE_RESTAURANT_GOOGLE_URL);

  React.useEffect(() => {
    getUserProfile();
    setIsButtonDisabled(false);
  }, [modalState]);

  return (
    <form
      className="new-event--form"
      onSubmit={async (e) => {
        e.preventDefault();
        setIsButtonDisabled(true);
        if (
          modalGroup === 'MLB Card' ||
          modalGroup === 'NBA Card' ||
          modalGroup === 'NHL Card' ||
          modalGroup === 'Fanatics Credit' ||
          modalGroup === 'Multi-day Gift Card'
        ) {
          const { data } = await client.mutate({
            mutation: UPDATE_OR_CREATE_FULLY_EDITABLE_CART_DETAILS,
            variables: {
              editCartDetailsInput: {
                cartId: modalState.cartId,
                packageDetailsId: modalState.packageDetailsId,
                cartData: JSON.stringify(modalState.cartData),
                invoiceId: +invoiceId,
                orderDetailsFilter: activeCardsFilter || '',
              },
              user: user,
            },
          });
          setPackageOrderWithDetails?.(data?.editFullyEditableCartDetails);
        } else if (modalGroup === 'Dinner') {
          updateRestaurantGoogleUrl({
            variables: {
              name: modalState.cartData.restaurantName,
              fullAddress: modalState.cartData.restaurantPlaceName,
              googleUrl: modalState.cartData.restaurantPlaceLink,
            },
          }).then(response => {
          }).catch(error => {
            console.error("Error updating Google URL:", error);
          });
            
          const ticketSentCopy = modalState.cartData.ticketsSentDate;
          const { data } = await client.mutate({
            mutation: UPDATE_OR_CREATE_CART_DETAILS,
            variables: {
              editCartDetailsInput: {
                cartId: modalState.cartId,
                packageDetailsId: modalState.packageDetailsId,
                cartData: {
                  ...modalState.cartData,
                  numberOfNights: Number(modalState.cartData.numberOfNights),
                  alternativeName: ((modalState.cartData?.alternativeFirstName || '') + ' ' + (modalState.cartData?.alternativeLastName || ''))?.trim(),
                  ticketsSentDate: ticketSentCopy === 'Invalid date' ? '-' : ticketSentCopy,
                },
                invoiceId: +invoiceId,
                orderDetailsFilter: activeCardsFilter || '',
              },
              user: user,
            },
          });

          setPackageOrderWithDetails?.(data?.editCartDetails);
        } else {
          const ticketSentCopy = modalState.cartData.ticketsSentDate;
          const { data } = await client.mutate({
            mutation: UPDATE_OR_CREATE_CART_DETAILS,
            variables: {
              editCartDetailsInput: {
                cartId: modalState.cartId,
                packageDetailsId: modalState.packageDetailsId,
                cartData: {
                  ...modalState.cartData,
                  numberOfNights: Number(modalState.cartData.numberOfNights),
                  ticketsSentDate: ticketSentCopy === 'Invalid date' ? '-' : ticketSentCopy,
                },
                invoiceId: +invoiceId,
                orderDetailsFilter: activeCardsFilter || '',
              },
              user: user,
            },
          });

          setPackageOrderWithDetails?.(data?.editCartDetails);
        }

        setPackageDetailsId(null);
        setModalGroup('');
        setModalName('');
        setModalState({});
        setAirportTransferType('transfer1');
        setVegasLimoTransferType('transfer1');
        setIsModalOpened(false);
        successMsg('Saved');
      }}
    >
      {Object.entries(CARD_FORM_SCHEMA).map(([key, value], id) => {
        if (key === modalGroup) {
          if (key === 'Airport Transfer') {
            const state = (modalState.cartData as any)?.[airportTransferType as keyof typeof value];

            return (
              <FormSection
                value={value?.[airportTransferType as keyof typeof value]}
                cartKey={key}
                handleInputs={handleAirportTransferInputs}
                setAirportTransferType={setAirportTransferType}
                airportTransferType={airportTransferType}
                setVegasLimoTransferType={setVegasLimoTransferType}
                vegasLimoTransferType={vegasLimoTransferType}
                modalState={state ? state : {}}
                key={id}
                setModalState={setModalState}
              />
            );
          }
          if (key === 'Vegas Limo Transfer') { 
            const state = (modalState.cartData as any)?.[vegasLimoTransferType as keyof typeof value];
            return (
              <FormSection
                value={value?.[vegasLimoTransferType as keyof typeof value]}
                cartKey={key}
                handleInputs={handleVegasLimoTransferInputs}
                setVegasLimoTransferType={setVegasLimoTransferType}
                vegasLimoTransferType={vegasLimoTransferType}
                setAirportTransferType={setAirportTransferType}
                airportTransferType={airportTransferType}
                modalState={state ? state : {}}
                key={id}
                setModalState={setModalState}
              />
            );
          }

          if (
            key === 'MLB Card' ||
            key === 'NBA Card' ||
            key === 'NHL Card' ||
            key === 'Fanatics Credit' ||
            key === 'Multi-day Gift Card'
          ) {
            return (
              <FormSection
                value={value}
                cartKey={key}
                handleInputs={handleInputs}
                setAirportTransferType={setAirportTransferType}
                airportTransferType={airportTransferType}
                setVegasLimoTransferType={setVegasLimoTransferType}
                vegasLimoTransferType={vegasLimoTransferType}
                modalState={modalState}
                setModalState={setModalState}
                key={id}
              />
            );
          }

          return (
            <FormSection
              value={value}
              cartKey={key}
              handleInputs={handleInputs}
              setAirportTransferType={setAirportTransferType}
              airportTransferType={airportTransferType}
              setVegasLimoTransferType={setVegasLimoTransferType}
              vegasLimoTransferType={vegasLimoTransferType}
              modalState={modalState}
              hanldeRevertCartToOriginal={hanldeRevertCartToOriginal}
              key={id}
              setModalState={setModalState}
            />
          );
        }
      })}
      <div className="modal-footer pt-0">
        <Button
          className="new-event--add text-uppercase"
          color="primary"
          type="submit"
          disabled={isButtonDisabled}
        >
          save
        </Button>
      </div>
    </form>
  );
};

export default EditCardForm;
