import { useLazyQuery, useMutation } from '@apollo/client';
import React from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Modal } from 'reactstrap';
import { successMsg } from '../../../../cache/vars';
import { DELETE_FILE } from '../../../../queries/vip-workflow';
import TicketTypeForm from './TicketTypeForm';
import UploadTicketsModalHeader from '../UploadTicketsModalHeader';
import {
  GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD,
  GET_PACKAGE_PO_INFO_FOR_TICKETS_DASHBOARD,
  GET_TICKETS_LIST_FOR_TICKETS_MODAL,
} from '../../../../queries/ticket';
import TicketsTransfer from './TicketsTransfer';
import UploadedTicketsList from './UploadedTicketsList';
import { GET_PROFILE } from '../../../../queries/auth';
import RenderFile from '../RenderFile';
import UploadedAdditionalUrls from '../desktop/UploadedAdditionalUrls';

const MobileUploadTicketsModal = ({
  isUploadTicketsModalOpened,
  setIsUploadTicketsModalOpened,
  packageDetails,
  invoiceId,
  isInvoiceArchied,
}: any) => {
  const [editFileOrUrlInput, setEditFileOrUrlInput] = React.useState('');
  const [ticketIdToEdit, setTicketIdToEdit] = React.useState(null);
  const [packageDetailsId, setPackageDetailsId] = React.useState<number | null>(null);

  const editFileNameRef = React.useRef(null);

  const [file, setFile] = React.useState('');
  const [isOpenFilePreview, setIsOpenFilePreview] = React.useState(false);

  const [getTickets, ticketsData] = useLazyQuery(GET_TICKETS_LIST_FOR_TICKETS_MODAL, {
    fetchPolicy: 'network-only',
  });

  const [getPackagePoList, packagePoList] = useLazyQuery(
    GET_PACKAGE_PO_INFO_FOR_TICKETS_DASHBOARD,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [deleteFile, deleteFileResponse] = useMutation(DELETE_FILE, {
    refetchQueries: [GET_TICKETS_LIST_FOR_TICKETS_MODAL, 'getTicketsListForTicketsModal', GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD],
    onCompleted() {
      successMsg('File(s) deleted');
    },
  });
  
  const [getUserProfile, getUserProfileResponse] = useLazyQuery(
    GET_PROFILE,
    {
      fetchPolicy: 'network-only',
    },
  );
  
  const user = getUserProfileResponse?.data?.getProfile?.username ? getUserProfileResponse?.data?.getProfile?.username : getUserProfileResponse?.data?.getProfile?.email;

  React.useEffect(() => {
    if (isUploadTicketsModalOpened) {
      getTickets({ variables: { invoiceId } });
      getUserProfile();
      getPackagePoList({
        variables: { invoiceIdList: [invoiceId] },
      });
    }
  }, [isUploadTicketsModalOpened]);

  React.useEffect(() => {
    function handleClickOutside(e: any) {
      if (
        editFileNameRef.current &&
        //@ts-expect-error
        !editFileNameRef.current.contains(e.target) &&
        e.target.nodeName !== 'BUTTON'
      ) {
        setEditFileOrUrlInput('');
        setTicketIdToEdit(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editFileNameRef]);

  return (
    <Modal
      isOpen={isUploadTicketsModalOpened}
      toggle={() => {
        setIsUploadTicketsModalOpened(false);
        setFile('');
      }}
      className="modal-dialog-centered modal-secondary"
      size="md"
      fade={false}
    >
      <div className="modal-body pb-3">
        <UploadTicketsModalHeader
          setIsUploadTicketsModalOpened={setIsUploadTicketsModalOpened}
          setFile={setFile}
          setIsOpenFilePreview={setIsOpenFilePreview}
        />
        {isOpenFilePreview ? (
          <RenderFile setFile={setFile} file={file} setIsOpenFilePreview={setIsOpenFilePreview} />
        ) : (
          <>
            <TicketTypeForm
              packageDetails={packageDetails}
              setPackageDetailsId={setPackageDetailsId}
              packageDetailsId={packageDetailsId}
              user={user}
            />
            <TicketsTransfer
              invoiceId={invoiceId}
              ticketsData={ticketsData}
              packagePoList={packagePoList?.data?.getPackagePoInfoForTicketsDahboard}
              isInvoiceArchied={isInvoiceArchied}
              user={user}
            />
            <UploadedTicketsList
              ticketsData={ticketsData}
              deleteFile={deleteFile}
              setEditFileOrUrlInput={setEditFileOrUrlInput}
              setTicketIdToEdit={setTicketIdToEdit}
              ticketIdToEdit={ticketIdToEdit}
              editFileOrUrlInput={editFileOrUrlInput}
              editFileNameRef={editFileNameRef}
              setFile={setFile}
              setIsOpenFilePreview={setIsOpenFilePreview}
              user={user}
            />
            
            {/* <UploadedAdditionalUrls
              ticketsData={ticketsData}
              setEditFileOrUrlInput={setEditFileOrUrlInput}
              setTicketIdToEdit={setTicketIdToEdit}
              ticketIdToEdit={ticketIdToEdit}
              editFileOrUrlInput={editFileOrUrlInput}
              editFileNameRef={editFileNameRef}
              user={user}
            /> */}
            
            {/* {ticketsData.data?.getTicketsListForTicketsModal &&
            Object.keys(uploadedTicketsList).length ? (
              <UploadedAdditionalUrls
                ticketsData={ticketsData}
                setEditFileOrUrlInput={setEditFileOrUrlInput}
                setTicketIdToEdit={setTicketIdToEdit}
                ticketIdToEdit={ticketIdToEdit}
                editFileOrUrlInput={editFileOrUrlInput}
                editFileNameRef={editFileNameRef}
                user={user}
              />
            ) : null} */}
          </>
        )}
      </div>
    </Modal>
  );
};

export default MobileUploadTicketsModal;
